.text-logo {
  text-align: center !important;
  padding: 5px !important;
  background: #008fcc;
  color: #fff;
  height: 70px;
}

.tempsidebaricon {
  color: #fff;
}

.ant-card-head-title {
  padding: 7px 0 !important;
}

.student-card {
  .ant-card-head {
    background: lightgray !important;
    color: rgb(50 140 210) !important;
    font-size: 16px !important;
    min-height: 40px !important;
    height: 40px !important;
  }
}

.mb-15 {
  margin-bottom: 15px !important;
}

.btn-disable:disabled,
.btn-disable:disabled:hover {
  background-color: #3e7456 !important;
  border: 1px solid #3e7456 !important;
  color: #fff !important;
}

.sec-nohover:hover {
  background-color: #198c36 !important;
  border: 1px solid #198c36 !important;
  color: #fff !important;
}

.content-space img {
  // max-width: 350px;
  // max-height: 400px;
  // width: 350px;
  // height: 400px;
  margin-left: 5%;
}

.react-quill-area {
  height: 250px;
  margin-bottom: 45px;
  width: 700px;
}

.helper-text {
  clear: both;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.rq-input {
  label {
    height: auto !important;
  }
}

.ant-layout-sider-collapsed {
  .text-logo {
    padding: 10px !important;
  }
}

.primary-icon {
  cursor: pointer;
  color: #007bff;
}
